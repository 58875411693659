#container
  width: 100%
  justify-content: space-between

#title
  display: flex
  justify-content: center
  align-items: center
  height: 20vh
  width: 100%
  text-align: center
  color: white
  top: 20%
  box-shadow: inset 0px -10px 5px 0px #ffffff
  background: url(../../assets/images/FUNDO-FEED-2.webp) no-repeat center center
  background-size: cover
  h1
    font-weight: 300
    margin: 0
    padding: 0
    height: 3rem
    color: white
#container
  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center
  gap: 20px

.cardImage
  width: 100%
  height: 100%
  max-height: 150px
  object-fit: cover

#price
  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center
  h2
    font-size: 1.4rem
    margin: 3%

#content
  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center
  height: 100%
  width: 100%


  
#btn
  display: flex
  flex-flow: column nowrap
  justify-content: center
  align-items: center
#container
    background: #333
    color : white
    justify-content: space-around
    min-height: 300px
    padding: 0 0 20px 
    z-index: 999
    width: 100%

#logo_container
    width: 300px
    min-height: 200px
    img
        object-fit: scale-down
#icon_container 
    display: flex
    justify-content: space-evenly
    width: 100%

.icon
    font-size: 1.5rem

.icon_social_media
    font-size: 1.5rem
    &:hover
        cursor: pointer
#contact
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  gap: 20px
  width: 100%
  &:hover
    cursor: pointer
    p
        text-decoration: underline
        color: #93d6a7

#under
  display: flex
  padding: 1rem 0
  background:#222
  width: 100%
  color: white

#overlink
  &:hover
    cursor: pointer
    text-decoration: underline
    color: #93d6a7
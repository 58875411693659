html 
  scroll-behavior: smooth


body
  margin: 0
  padding: 0
  font-family: -apple-system, 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
  -font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #333


.container
  display: flex
  align-items: center
  justify-content: center
  &.row
    flex-direction: row
    flex-wrap: wrap
  &.row-reverse
    flex-direction: row-reverse
    flex-wrap: wrap
  &.column
    flex-direction: column
    flex-wrap: nowrap
  &.column-reverse
    flex-direction: column-reverse
    flex-wrap: nowrap
  &.autowrap
    flex-direction: row
    flex-wrap: wrap


#container
    display: flex
    min-height: 45px
    flex: 1
    gap: 10px

#text 
    padding: 0
    font-size: 1.5rem
    width: 100%
    padding: 0 5%
    h1
        width: 100%
        font-weight: 700
        margin: 1rem 0
    p
        width: 100%
        font-weight: 400
        font-size: 1.2rem
        margin: 0

@media only screen and (max-width: 767px) 
    #text
        padding: 0 1%
        h1
            font-size: 1.4rem
        p
            font-size: 1.1rem

@media only screen and (max-width: 520px) 
    #text
        padding: 0%
        h1
            font-size: 1.3rem
            margin: 0.5rem 0
        p
            font-size: 1rem
            

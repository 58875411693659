#container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  width: 100%
  background: #66ead3

#content
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  width: 100%
  height: 100%

  .contentColumn
    display: flex
    flex-direction: column
    justify-content: center
    min-width: 20%
    height: 100%
    .item
      display: flex
      flex-direction: column
  .contentRow
    display: flex
    flex-flow: row wrap
    justify-content: space-around 
    gap: 50px
    height: 100%
    width: 80%
    padding: 20px 30px
    .cardItem
      display: flex
      justify-content: center
      flex: 1 0 15%
      .description
        display: flex
        flex-direction: column
        justify-content: space-between
        height: 100%

.brazAdvantages
  display: flex
  flex-direction: row
  margin: 0 5%
  gap: 10px

.footers
  height: 80px
  width: 100%
  object-fit: cover


@media only screen and (max-width: 1600px)
  #content
    flex-direction: column
    .contentRow
      width: 90%
      gap: 20px
    .contentColumn
      flex-direction: row
      justify-content: space-around
      width: 100%
      height: 100%
      .item
        display: flex

@media only screen and (max-width: 1200px)
  #content
    .contentRow
      justify-content: center
      .cardItem
        justify-content: center
@media only screen and (max-width: 520px) 
    #container
      width: 100%
      min-height: 800px
    #content
      flex-direction: column
      min-height: 200px
      width: 94%
      padding: 0 3%

      .contentRow
        padding: 20px 0
        .cardItem
          flex: 1 0 100%
          .description
            display: flex
            flex-direction: column
            justify-content: space-between
            height: 100%
      .contentColumn
        display: flex
        justify-content: start
        flex-direction: column
        height: 60%
        width: 100%
      .contentImg
        display: flex
        width: 100%
        height: 100%
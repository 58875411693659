#container_features
  padding: 80px 5%
  width: 90%

#container
    gap: 50px
    width: 100%
    height: 100%
    align-items: stretch

.about_us
  font-size: 1.5rem
  color: #fff
  margin-bottom: 30px

.containerImg
  width: 90vw
  top: 20%
  box-shadow: inset 0px -10px 5px 0px #ffffff
  background: url(../../../../assets/images/FUNDO-FEED-2.webp) no-repeat center center
  background-size: cover

#item
  flex: 1
  background: white
  min-width: 220px
  max-width: 300px
  padding: 12px
  border: 1px solid #c1c1c1
  border-radius: 6px
  justify-content: center
  cursor: pointer
  &:hover
      transition: 0.3s
      transform: scale(1.05)
  &:not(:hover)
      transition: 0.3s
      transform: scale(1)
  img
      width: 180px
      object-fit: cover

#typography
    position:absolute

@media only screen and (max-width: 520px) 
    #container_features
        align-items: start
        text-align: left
        #container
            width: 100%
            flex-wrap: wrap
            #item
                width: 100%
                height: 100%

@media only screen and (max-width: 767px) 
    #container_features
        align-items: start
        text-align: left
        #container
            width: 100%
            flex-wrap: wrap
            #item
                width: 100%
                height: 100%



@media only screen and (max-width: 768px) 
    #item
        #overlink
            width: 100%
#container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 50px 0
  width: 100%
  height: 100%

#presentation
  background-color: #fff
  width: 100%

#values
  background-color: #f5f5f5
  width: 100%

.item
  display: flex
  min-height: 90vh
  .img
    width: 100%
    max-width: 700px
  .text
    max-width: 700px
    font-size: 1.5rem
    font-weight: 300
    text-align: center
    margin: 0 50px
  .points
     width: 100%
#container
    position: sticky
    top: 0
    left: 0
    z-index: 999
    justify-content: space-between
    width: 100%
    height: 70px

.scrolled
    background-color: #fff
    box-shadow: 0 0 10px rgba(0,0,0,0.1)
    transition: all 0.1s ease-in-out
.not_scrolled
    background-color: transparent
    box-shadow: none
    transition: all 0.1s ease-in-out

#button
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  height: 3rem


#logo_container
    width: 100px
    height: 70px
    margin-left: 3% 
    cursor: pointer
    img
        width: 100%
        height: 100%
        object-fit: scale-down

#btn_row
    display: flex
    align-items: center
    justify-content: space-between
    gap: 10px
    margin-right: 5%
    button 
        display: flex

.icon
    font-size: 1.25rem


#phone_row
    display: flex
    align-items: center
    justify-content: space-between
    gap: 10px
    button 
        display: flex
    
#animated_border
    animation: gradient 1.5s ease-in-out infinite alternate
    background: linear-gradient(-45deg,#0625d8,#ff0)
    background-size: auto
    background-size: 300% 300%
    bottom: 0
    height: 3px
    position: absolute
    width: 100%

@media screen and (max-width: 768px)
    #phone_row
        padding: 10px 0
        gap: 20px
        button
            height: 70px
            padding: 10px 20px
            font-size: 1.5rem

@keyframes gradient 
  0% 
    background-position: 0% 100%
  
  100% 
    background-position: 100% 0%
  

#container
    justify-content: space-around
    width: 100%
    min-height: 800px
    iframe
        border: none

#locations
    text-align: center
    font-size: 1.12rem
    width: 330px

#iframe
    display: flex
    flex-flow: column nowrap
    justify-content: center
    align-items: center
    .buttons
        display: flex
        justify-content: center
        width: 100%
        margin: 30px

@media only screen and (max-width: 1000px) 
    #container
      justify-content: center
      align-content: center
      height: 1000px
    #locations
        width: 100%
        font-size: 1rem


@media only screen and (max-width: 567px) 
    #locations
        width: 100%
        font-size: 1rem